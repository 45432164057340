import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ApicallService } from "src/app/services/apicall.service";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  megaMenu?: boolean;
  image?: string;
  active?: boolean;
  badge?: boolean;
  badgeText?: string;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  constructor(private apicall: ApicallService) {
    this.GetData();
  }
  public cat: any = [];
  public screenWidth: any;
  public leftMenuToggle: boolean = false;
  public mainMenuToggle: boolean = false;

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      title: "home",
      type: "link",
      active: false,
      path: "./home/fashion",
    },
    {
      title: "All Products",
      type: "link",
      badge: true,
      path: "/home/products",
      badgeText: "new",
      active: false,
    },

    {
      title: "Products Lists",
      type: "sub",
      badge: true,
      badgeText: "new",
      active: false,
    },
    {
      title: "Stores",
      type: "link",
      badge: true,
      path: "/home/storedetail",
      badgeText: "new",
      active: false,
    },

    {
      title: "pages",
      type: "sub",
      active: false,
      children: [
        {
          title: "profile",
          path: "/pages/profile",
          type: "link",
        },
        // { path: "/pages/aboutus", title: "about-us", type: "link" },

        { path: "/pages/lookbook", title: "orders", type: "link" },
        // {
        // 	title: 'compare', type: 'sub', active: false, children: [
        // 		{ path: '/pages/compare/one', title: 'compare-1', type: 'link' },
        // 		{ path: '/pages/compare/two', title: 'compare-2', type: 'link', badge: true, badgeText: 'new' }
        // 	]
        // },
        // { path: '/pages/collection', title: 'collection', type: 'link' },
        // { path: '/pages/lookbook', title: 'lookbook', type: 'link' },
        // { path: '/pages/404', title: '404', type: 'link' },
        // { path: '/pages/comingsoon', title: 'coming-soon', type: 'link', badge: true, badgeText: 'new' },
        { path: "/pages/faq", title: "faq", type: "link" },
      ],
    },
  ];
  GetData() {
    this.apicall.allCatagory().subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        res[i].path = "/home/vegetable";
        res[i].type = "link";
        res[i].stype = "img";
        this.MENUITEMS[2].children = res;
      }
    });
  }

  LEFTMENUITEMS: Menu[] = [
    {
      title: "clothing",
      type: "sub",
      megaMenu: true,
      active: false,
      children: [
        {
          title: "mens fashion",
          type: "link",
          active: false,
          children: [
            { path: "/home/fashion", title: "sports wear", type: "link" },
            { path: "/home/fashion", title: "top", type: "link" },
            { path: "/home/fashion", title: "bottom", type: "link" },
            { path: "/home/fashion", title: "ethic wear", type: "link" },
            { path: "/home/fashion", title: "sports wear", type: "link" },
            { path: "/home/fashion", title: "shirts", type: "link" },
            { path: "/home/fashion", title: "bottom", type: "link" },
            { path: "/home/fashion", title: "ethic wear", type: "link" },
            { path: "/home/fashion", title: "sports wear", type: "link" },
          ],
        },
        {
          title: "women fashion",
          type: "link",
          active: false,
          children: [
            { path: "/home/fashion", title: "dresses", type: "link" },
            { path: "/home/fashion", title: "skirts", type: "link" },
            { path: "/home/fashion", title: "westarn wear", type: "link" },
            { path: "/home/fashion", title: "ethic wear", type: "link" },
            { path: "/home/fashion", title: "bottom", type: "link" },
            { path: "/home/fashion", title: "ethic wear", type: "link" },
            { path: "/home/fashion", title: "sports wear", type: "link" },
            { path: "/home/fashion", title: "sports wear", type: "link" },
            { path: "/home/fashion", title: "bottom wear", type: "link" },
          ],
        },
      ],
    },
    {
      title: "bags",
      type: "sub",
      active: false,
      children: [
        { path: "/home/fashion", title: "shopper bags", type: "link" },
        { path: "/home/fashion", title: "laptop bags", type: "link" },
        { path: "/home/fashion", title: "clutches", type: "link" },
        {
          path: "/home/fashion",
          title: "purses",
          type: "link",
          active: false,
          children: [
            { path: "/home/fashion", title: "purses", type: "link" },
            { path: "/home/fashion", title: "wallets", type: "link" },
            { path: "/home/fashion", title: "leathers", type: "link" },
            { path: "/home/fashion", title: "satchels", type: "link" },
          ],
        },
      ],
    },
    {
      title: "footwear",
      type: "sub",
      active: false,
      children: [
        { path: "/home/fashion", title: "sport shoes", type: "link" },
        { path: "/home/fashion", title: "formal shoes", type: "link" },
        { path: "/home/fashion", title: "casual shoes", type: "link" },
      ],
    },
    {
      path: "/home/fashion",
      title: "watches",
      type: "link",
    },
    {
      title: "Accessories",
      type: "sub",
      active: false,
      children: [
        { path: "/home/fashion", title: "fashion jewellery", type: "link" },
        { path: "/home/fashion", title: "caps and hats", type: "link" },
        { path: "/home/fashion", title: "precious jewellery", type: "link" },
        {
          path: "/home/fashion",
          title: "more..",
          type: "link",
          active: false,
          children: [
            { path: "/home/fashion", title: "necklaces", type: "link" },
            { path: "/home/fashion", title: "earrings", type: "link" },
            {
              path: "/home/fashion",
              title: "rings & wrist wear",
              type: "link",
            },
            {
              path: "/home/fashion",
              title: "more...",
              type: "link",
              active: false,
              children: [
                { path: "/home/fashion", title: "ties", type: "link" },
                { path: "/home/fashion", title: "cufflinks", type: "link" },
                {
                  path: "/home/fashion",
                  title: "pockets squares",
                  type: "link",
                },
                { path: "/home/fashion", title: "helmets", type: "link" },
                { path: "/home/fashion", title: "scarves", type: "link" },
                {
                  path: "/home/fashion",
                  title: "more...",
                  type: "link",
                  active: false,
                  children: [
                    {
                      path: "/home/fashion",
                      title: "accessory gift sets",
                      type: "link",
                    },
                    {
                      path: "/home/fashion",
                      title: "travel accessories",
                      type: "link",
                    },
                    {
                      path: "/home/fashion",
                      title: "phone cases",
                      type: "link",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/home/fashion",
      title: "house of design",
      type: "link",
    },
    {
      title: "beauty & personal care",
      type: "sub",
      active: false,
      children: [
        { path: "/home/fashion", title: "makeup", type: "link" },
        { path: "/home/fashion", title: "skincare", type: "link" },
        { path: "/home/fashion", title: "premium beaty", type: "link" },
        {
          path: "/home/fashion",
          title: "more..",
          type: "link",
          active: false,
          children: [
            { path: "/home/fashion", title: "fragrances", type: "link" },
            { path: "/home/fashion", title: "luxury beauty", type: "link" },
            { path: "/home/fashion", title: "hair care", type: "link" },
            { path: "/home/fashion", title: "tools & brushes", type: "link" },
          ],
        },
      ],
    },
    {
      path: "/home/fashion",
      title: "home & decor",
      type: "link",
    },
    {
      path: "/home/fashion",
      title: "kitchen",
      type: "link",
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);
}
