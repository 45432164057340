import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Preferences } from "@capacitor/preferences";
import { remove } from "../localStorage/LocalStorage";
import { BehaviorSubject, Observable } from "rxjs";

// export const apiUrl = "https://learn2earnn.com/products/cashgrow/public/";
export const apiUrl = "https://thecodingverse.com/L2E/dadyeco/public/";
// export const apiUrl = "http://localhost/dadyeco/public/";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private selectedCurrencySubject: BehaviorSubject<string> = new BehaviorSubject<string>('USD'); // Default currency
  selectedCurrency$: Observable<string> = this.selectedCurrencySubject.asObservable();


  private selectedCurrency: string = 'USD';
  constructor(public http: HttpClient, public router: Router) {}
  pric:any 
  setSelectedCurrency(currency: string): void {
    this.selectedCurrencySubject.next(currency);
  }
  
getSelectedCurrency(): Observable<string> {
  return this.selectedCurrency$;
}
  con(data: any, type: any) {
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl + type, JSON.stringify(data)).subscribe(
        (res) => {
          resolve(JSON.stringify(res));
        },
        (err) => {
          reject(err);
          console.log(err);
        }
      );
    });
  }

  // geting posts

  getdata(type: any) {
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl + type).subscribe(
        (res) => {
          resolve(JSON.stringify(res));
        },
        (err) => {
          reject(err);
          console.log(err);
        }
      );
    });
  }

  // public isLoggedIn(): boolean {
  //   const result = Preferences.get({ key: 'getUser' })
  //   console.log(result)
  //   if (result == null) {
  //     return false;
  //   } else
  //     return true;
  // }

  public isLoggedIn(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      Preferences.get({ key: "user" })
        .then((result: any) => {
          const x = result.value;
          console.log(x);
          resolve(x !== null);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          resolve(false); // Resolve to false in case of an error
        });
    });
  }

  async logout() {
    await remove("user");
    this.router.navigate(["/pages/login"]);
  }
}
