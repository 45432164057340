<!-- thank-you section start -->
<section class="section-b-space light-layout">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="success-text">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <h2>Thank you</h2>
                    <p>Order is successfully processsed and is on the way</p>
                    <!-- <p>Transaction ID:267676GHERT105467</p>
                     -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->
<!-- order-detail section start -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="product-order" *ngIf="data.length">
                    <h3>your order details</h3>
                    <div class="row product-order-detail" *ngFor="let product of data; let i = index">
                        <div class="col-12 col-sm-12 col-md-3">
                            <!-- <img src="assets/images/product/fashion/1.jpg" alt="" class="img-fluid"> -->
                            <img  [src]="product.images[0].src" [alt]="product.images[0].alt">
                        </div>
                        <div class="col-3 order_detail">
                            <div>
                                <h4>product name</h4>
                                <h5>{{product.title}}</h5>
                            </div>
                        </div>
                        <div class="col-3 order_detail">
                            <div>
                                <h4>quantity</h4>
                                <h5>{{product.quantity}}</h5>
                            </div>
                        </div>
                        <div class="col-3 order_detail " >
                            <div >
                                <h4>price</h4>
                                <h5 class="td-color" *ngIf="selectedCurrency === 'dprice'">
                                    {{roundAmount(totalPrice[i]/dollar)}}$
                                  </h5>
                                  <h5 class="td-color" *ngIf="selectedCurrency !== 'dprice'">
                                    {{totalPrice[i]}}PKR
                                  </h5>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="total-sec">
                        <ul>
                            <li>subtotal <span>$55.00</span></li>
                            <li>shipping <span>$12.00</span></li>
                            <li>tax(GST) <span>$10.00</span></li>
                        </ul>
                    </div> -->
                    <!-- <div class="final-total">
                        <h3>total <span>{{data.grandTotal}}</span></h3>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row order-success-sec">
                    <div class="col-sm-6">
                        <h4>summery</h4>
                        <ul class="order-detail">
                            <!-- <li>order ID: {{orderid}}</li> -->
                            <li>Order Date: {{ getCurrentTime() }}</li>
                            <!-- <li>Order Total:{{data.grandTotal}}</li> -->
                        </ul>
                    </div>
                    <!-- <div class="col-sm-6">
                        <h4>shipping address</h4>
                        <ul class="order-detail">
                            <li>{{item.order[0].address}}</li>
                            <li>568, suite ave.</li>
                            <li>Austrlia, 235153</li>
                            <li>Contact No. 123456789</li>
                        </ul>
                    </div> -->

                    <div class="col-sm-12 payment-mode">
                        <h4>payment method</h4>
                        <p>Pay on Delivery (Cash/Card). Cash on delivery (COD) available. Card/Net banking acceptance subject to device availability.</p>
                    </div>
                    <div class="col-md-12">
                        <div class="delivery-sec">
                            <h3>expected date of delivery</h3>
                            <h2> {{ getDateTimeAfterWeek() }}</h2>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- Section ends -->