import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
// import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { environment } from "../../../environments/environment";
import { Product } from "../../shared/classes/product";
import { ProductService } from "../../shared/services/product.service";
import { OrderService } from "../../shared/services/order.service";
import { Router } from '@angular/router';
import { ApicallService } from 'src/app/services/apicall.service';
import { check } from 'src/app/localStorage/LocalStorage';
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.scss']
})
export class OrderSuccessComponent implements OnInit {
  data: any;
  public products: any = [];
  public amount: any;
  all: any;
  discount: any;
  discountedPrice: number[] = [];
  totalPrice: number[] = [];
  selectedCurrency: any;
  dollar=280;
  // public grandTotal: number = 0;
  // orderid: any;
  constructor(private fb: UntypedFormBuilder,public router:Router,public apicall:ApicallService,
    public productService: ProductService,
    private orderService: OrderService,public auth:AuthService
  ) { 
    this.data=history.state.user
    console.log('data123',this.data)
    this.auth.getSelectedCurrency().subscribe(currency => {
      this.selectedCurrency = currency;
      console.log('selected',this.selectedCurrency)
    });
    // this.orderid=history.state.id
    // console.log('dataid',this.orderid)
    // console.log('all',this.all.address)
    
  }

  ngOnInit(): void {
    this.total();
    this.productService.cartItems.subscribe(
      (response) => (this.products = response)
    );
    this.getTotal.subscribe((amount) => (this.amount = amount));
    
    // this.initConfig();
  }
  roundAmount(num:any): number {
    return num.toFixed(2);
  }
  getCurrentTime() {
    const currentTime = new Date();
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = currentTime.toLocaleDateString(undefined, options);
    return formattedDate;
}
getDateTimeAfterWeek() {
   // Get the current date and time
   const currentDate = new Date();

   // Add 7 days to the current date
   const afterWeekDate = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000));

   // Set the time after one week (e.g., 12:00:00 PM)
    // Set the time to 12:00:00 PM

   // Format the date and time, hour: 'numeric', minute: 'numeric', second: 'numeric'
   const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
   const formattedDate = afterWeekDate.toLocaleDateString(undefined, options);

   return formattedDate;
}

  // total() {
    // this.data.grandTotal = 0;
    // for (let i = 0; i < this.data.length; i++) {
    //   this.discount=    (this.data[i].price * this.data[i].discount) / 100;
    //   this.discountedPrice = this.data.price - this.discount;
    //   console.log('discount',this.discountedPrice)
      // this.data[i].basicprice = this.data[i].quantity * this.data[i].price;
      // this.data.grandTotal = this.data.grandTotal +this.data[i].basicprice;
      // this.carditem.shipping = this.carditem.shipping + this.carditem.grandTotal;
    // }
    // console.log("Grand Total:", this.grandTotal);
    // console.log(this.carditem.shipping);
    
  // }
  total() {
    for (let i = 0; i < this.data.length; i++) {
      let discount = (this.data[i].price * this.data[i].discount) / 100; // Calculate discount
      let discountedPrice = this.data[i].price - discount; // Calculate discounted price
      let totalPrice = discountedPrice * this.data[i].quantity; // Calculate total price (discounted price * quantity)
      this.discountedPrice.push(discountedPrice);
      this.totalPrice.push(totalPrice);
  }
}
  public get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }
  }

