<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <!-- <span class="lable3" *ngIf="product.new">new</span> -->
      <!-- <span class="lable4" *ngIf="product.sale">on sale</span> -->
    </div>
    <!-- <div class="front">
      <a>
        <img style="height: 350px; width: 300px" (click)="state(product)" src="{{ product?.images[0].src }}"
          class="img-fluid lazy-loading" />
      </a>
    </div> -->
    <div class="front mt-2">
      <a (click)="state(product)">
        <img
          [defaultImage]="
            ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'
          "
          [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src"
          class="img-fluid lazy-loading heigth1"
          alt="{{ product.images[0].alt }}"
        />
      </a>
    </div>
    <div class="back h2" *ngIf="onHowerChangeImage">
      <a
        [routerLink]="[
          '/shop/product/left/sidebar/',
          product.title.replace(' ', '-')
        ]"
      >
        <img
          [src]="ImageSrc ? ImageSrc : product.images[1].src"
          class="img-fluid lazy-loading"
          alt="{{ product.images[1].alt }}"
        />
      </a>
    </div>
    <ul class="product-thumb-list h2" *ngIf="thumbnail">
      <li
        class="grid_thumb_img"
        [class.active]="ImageSrc === image.src"
        *ngFor="let image of product.images"
      >
        <a href="javascript:void(0)" (click)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src" />
        </a>
      </li>
    </ul>

    <div class="cart-info cart-wrap">
      <a
        href="javascript:void(0)"
        title="Add to cart"
        (click)="CartModal.openModal(product)"
        *ngIf="cartModal"
      >
        <i class="ti-shopping-cart"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Add to cart"
        (click)="addToCart(product)"
        *ngIf="!cartModal"
      >
        <i class="ti-shopping-cart"></i>
      </a>
      <!-- <a href="javascript:void(0)" title="Add to Wishlist" class="h2"  (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a> -->
      <!-- <a href="javascript:void(0)" title="Quick View" class="h2"  (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a> -->
      <!-- <a href="javascript:void(0)" title="Compare" class="h2"  (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a> -->
    </div>
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a
        [routerLink]="[
          '/shop/product/left/sidebar/',
          product?.title.replace(' ', '-')
        ]"
      >
        <h6>{{ product?.title | titlecase }}</h6>
        <h2 style="color: rgb(86, 86, 86); font-weight: normal">
          {{ product?.title | titlecase }}
        </h2>
      </a>
      <p>{{ product?.description }}</p>
      <h4 *ngIf="selectedCurrency === 'dprice'">
        {{
          roundAmount(
            (product?.price * currency?.price | discount : product) / dollar
          )
        }}$
        <!-- {{roundAmount(pricea[0].pr/dollar)}}$ -->

        <del *ngIf="product?.discount"
          ><span class="money">
            {{
              roundAmount((product?.price * currency?.price) / dollar)
            }}$</span
          ></del
        >
      </h4>
      <h2 *ngIf="selectedCurrency === 'dprice'">
        {{
          roundAmount(
            (product?.price * currency?.price | discount : product) / dollar
          )
        }}$
        <!-- {{roundAmount(pricea[0].pr/dollar)}}$ -->

        <del *ngIf="product?.discount"
          ><span class="money">
            {{
              roundAmount((product?.price * currency?.price) / dollar)
            }}$</span
          ></del
        >
      </h2>
      <h4 *ngIf="selectedCurrency !== 'dprice'">
        {{ product?.price * currency?.price | discount : product }}PKR
        <!-- {{roundAmount(pricea[0].pr/dollar)}}$ -->

        <del class="del" *ngIf="product?.discount"
          ><span class="money">
            {{ product?.price * currency?.price }}PKR</span
          ></del
        >
        <del class="deln" *ngIf="product?.discount"
          ><span class="money">
            {{ product?.price * currency?.price }}PKR</span
          ></del
        >
      </h4>
      <h2 *ngIf="selectedCurrency !== 'dprice'">
        {{ product?.price * currency?.price | discount : product }}PKR
        <!-- {{roundAmount(pricea[0].pr/dollar)}}$ -->

        <del class="del" *ngIf="product?.discount"
          ><span class="money">
            {{ product?.price * currency?.price }}PKR</span
          ></del
        >
        <del class="deln" *ngIf="product?.discount"
          ><span class="money">
            {{ product?.price * currency?.price }}PKR</span
          ></del
        >
      </h2>
      <ul class="color-variant" *ngIf="Color(product?.variants).length">
        <li
          style="width: 16px !important; height: 16px !important"
          [class]="color"
          *ngFor="let color of Color(product?.variants)"
          [ngStyle]="{ 'background-color': color }"
          (click)="ChangeVariants(color, product)"
        ></li>
      </ul>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view
  #quickView
  [product]="product"
  [currency]="currency"
></app-quick-view>
<app-cart-modal
  #cartModal
  [product]="product"
  [currency]="currency"
  *ngIf="cartModal"
></app-cart-modal>
