import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { check, set } from "src/app/localStorage/LocalStorage";
import { ApicallService } from "src/app/services/apicall.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  public userobj: any = {};
  public user: any;
  public signupdata: any = {
    lastname: "",
    contactno: "",
    email: "",
    password: "",
    address: "",
    invitationlink: "google",
    type: "user",
  };
  public newpassword: any;
  public showmessage: any;
  public isChecked: any = false;
  public userFirstName: any;
  constructor(
    private apiCall: ApicallService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private toast: ToastService
  ) {
    // this.GetUserData();
    // this.activateRoute.queryParams.subscribe((params: any) => {
    //   console.log(params);
    //   if (Object.keys(params).length === 0 || params.id == '') {
    //     console.log('No invitation link');
    //     this.toast.ErrorToast('Invalid Invitation Link', 'Sorry!');
    //     this.route.navigate(['home']);
    //   } else {
    //     this.signupdata.invitationlink = params.id;
    //   }
    // });
  }

  emailErrorMessage = "";
  public async GetUserData() {
    this.user = await check("user");
    this.userobj = JSON.parse(this.user);
    console.log(this.userobj);
  }
  validateEmailDomain(email: string): void {
    const requiredDomain = "@gmail.com";
    if (!email.endsWith(requiredDomain)) {
      this.emailErrorMessage = `Email must end with ${requiredDomain}`;
    } else {
      this.emailErrorMessage = "";
    }
  }

  password($event: any) {
    console.log($event.target.value);
    this.newpassword = $event.target.value;
  }
  confirmpassword($event: any) {
    console.log($event.target.value);
    if ($event.target.value == this.newpassword) {
      console.log("password correct");
      this.showmessage = "Correct Password";
      this.signupdata.password = $event.target.value;
    } else {
      console.log("password not match");
      this.showmessage = "Password Not Matched!";
    }
  }

  public submit() {
    this.signupdata.firstname =
      this.userFirstName + " " + this.signupdata.lastname;
    console.log(this.signupdata);
    this.apiCall.signup(this.signupdata).subscribe((res) => {
      console.log(res);
      if (res.error === false) {
        set("user", res?.data);
        this.signupdata = {
          firstname: "",
          lastname: "",
          contactno: "",
          email: "",
          password: "",
          address: "",
        };
        this.userFirstName = "";
        this.route.navigate(["/pages/login"], {
          state: {
            user: res.error,
          },
        });
        this.toast.SuccessToast("Account Created Succsessfully", "Good Job!");
      } else {
        alert("InValid Credential");
        // set('user', res?.data);
        // this.route.navigate(['/default/index']);
      }
    });
  }

  ngOnInit(): void {}
}
