import { Component, OnInit } from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { Router } from "@angular/router";
import { state } from "@angular/animations";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[];
  interval: any;

  constructor(private router: Router, public navServices: NavService) {
    this.navServices.items.subscribe((menuItems) => {
      console.log(menuItems, "menu");
      this.menuItems = menuItems;
    });
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {}

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }
  downloadApk(): void {
    // Replace with the actual URL of your APK file
    const a = document.createElement("a");
    // Create a hidden anchor element
    a.href = "https://daddyappsdownload.shop/";
    a.target = "_blank";

    a.click();

    // Append the anchor to the body

    // Programmatically click the anchor to trigger the download
  }
  // Click Toggle menu (Mobile)
  toggletNavActive(item: any) {
    item.active = !item.active;
    // console.log('asif');
  }
  state(item: any) {
    if (item.path === "/home/vegetable") {
      this.router.navigate(["home/vegetable"], {
        queryParams: {
          special: JSON.stringify(item),
        },
      });
      console.log("asif", item);
    } else {
      console.log(".....................ss.......");
    }
  }
}
