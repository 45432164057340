<app-breadcrumb [title]="'Register'" [breadcrumb]="'Register'"></app-breadcrumb>
<!-- section start -->
<section class="register-page section-b-space">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h3>create account</h3>
        <div class="theme-card">
          <form class="theme-form">
            <div class="row">
              <div class="col-md-6">
                <label for="email">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="fname"
                  placeholder="First Name"
                  required=""
                  [(ngModel)]="userFirstName"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
              <div class="col-md-6">
                <label for="review">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="lname"
                  placeholder="Last Name"
                  required=""
                  [(ngModel)]="signupdata.lastname"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label for="email">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Email"
                  required
                  [(ngModel)]="signupdata.email"
                  [ngModelOptions]="{ standalone: true }"
                  (input)="validateEmailDomain(signupdata.email)"
                />
                <span *ngIf="emailErrorMessage" class="text-danger">
                  {{ emailErrorMessage }}
                </span>
              </div>

              <div class="col-md-6">
                <label for="review">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="review"
                  placeholder=" password"
                  required=""
                  (input)="password($event)"
                />
              </div>
              <div class="col-md-6">
                <label for="review"> Confirm Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="review"
                  placeholder="Confirm password"
                  required=""
                  (input)="confirmpassword($event)"
                />
                <span
                  class=""
                  *ngIf="this.showmessage != ''"
                  [ngClass]="
                    this.showmessage == 'Correct Password'
                      ? 'text-success'
                      : 'text-danger'
                  "
                  >{{ this.showmessage }}
                </span>
              </div>
              <div class="col-md-6">
                <label for="email">Contact Number</label>
                <input
                  type="number"
                  class="form-control"
                  id="fname"
                  placeholder="contact number"
                  required=""
                  maxlength="11"
                  [(ngModel)]="signupdata.contactno"
                  [ngModelOptions]="{ standalone: true }"
                  pattern="[0-11]{11}"
                  maxlength="11"
                  min="0"
                  max="9999"
                  step="1"
                />
              </div>
              <div class="col-md-6">
                <label for="review">Address</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Valid Address"
                  required=""
                  [(ngModel)]="signupdata.address"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
              <div class="col-md-12 mt-3">
                <p
                  class="d-flex text-center"
                  style="font-family: Poppins, sans-serif"
                >
                  <input
                    class="form-check-input border border-1 border-dark p-1 text-black mx-2"
                    [(ngModel)]="isChecked"
                    [ngModelOptions]="{ standalone: true }"
                    type="checkbox"
                    style="margin-top: 12px"
                  />
                  <label
                    class="form-check-label m-auto text-black text-center text-black mx-1 mt-2"
                    style="font-size: 12px; color: black"
                  >
                    &nbsp;&nbsp;&nbsp; I have read and agree to the
                    <a
                      class="term text-black"
                      style="font-size: 12px"
                      href="#/tems"
                      >Terms and Condition</a
                    >
                  </label>
                </p>
              </div>
            </div>
            <button
              class="btn btn-solid"
              [disabled]="
                userFirstName == '' ||
                signupdata.lastname == '' ||
                signupdata.contactno == '' ||
                signupdata.email == '' ||
                signupdata.password == '' ||
                this.showmessage != 'Correct Password' ||
                isChecked == false ||
                emailErrorMessage !== ''
              "
              (click)="submit()"
            >
              create Account
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Section ends-->
