import { Component, OnInit, Input } from "@angular/core";
import { Product } from "../../../../shared/classes/product";
import { ProductService } from "../../../../shared/services/product.service";
import { ApicallService } from "src/app/services/apicall.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-related-product",
  templateUrl: "./related-product.component.html",
  styleUrls: ["./related-product.component.scss"],
})
export class RelatedProductComponent implements OnInit {
  @Input() type: string;

  public products: Product[] = [];

  constructor(public productService: ApicallService) {
    this.productService
      .getproducts()
      .subscribe(
        (response) =>
          (this.products = response.filter((item) => item.type == this.type))
      );
  }

  ngOnInit(): void {}
}
