import { Component, OnInit } from "@angular/core";
import { Product } from "../../classes/product";
import { ProductService } from "../../services/product.service";
import { ApicallService } from "src/app/services/apicall.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent implements OnInit {
  public products: any = [];
  public collapse: boolean = true;

  constructor(public productService: ApicallService,public router:Router) {
    this.productService
      .getproducts()
      .subscribe((product) => (this.products = product));
      console.log('getproducts',this.products);
  }
  


public categorys:any=[{title:"shoes",name:'Shose'},{title:"bag",name:'Bag'},{title:"Electronics",name:'Electronics'},{title:"Dress",name:'Dress'},{title:"Fashion\r\n",name:'Fashion'},]
  ngOnInit(): void {}

  get filterbyCategory() {
    const category = [...new Set(this.products.map((product) => product.type))];
    return category;
  }
 
    state(item: any) {
        this.router.navigate(["/home/vegetable"], {
          queryParams: {
            special: JSON.stringify(item),
          },
        });
        console.log("asif", item);
    }

}
