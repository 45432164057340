<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Welcome to Our Daddy store</li>
              <li>
                <i class="fa fa-phone" aria-hidden="true"></i>Call Us: 123 - 456
                - 7890
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-end">
          <ul *ngIf="userobj !== null" class="header-dropdown">
            <!-- <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
            </li>
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>
            </li> -->
            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> My Account
              <ul class="onhover-show-div justify-content-center">
                <li *ngIf="userobj !== null">
                  <img
                    *ngIf="userobj !== null"
                    [routerLink]="['/pages/profile']"
                    src="{{ userobj.image }}"
                    style="
                      border-radius: 25px;
                      margin-left: auto;
                      margin-right: auto;
                    "
                    height="50px"
                    width="50px"
                    alt=""
                  />
                </li>
                <li class="text-black">{{ userobj.name }}</li>
                <li class="text-black">{{ userobj.email }}</li>
                <li class="text-black">{{ userobj.contactNumber }}</li>
                <li *ngIf="!islogin">
                  <a data-lng="en" [routerLink]="['/pages/login']"> Login </a>
                </li>
                <li *ngIf="!islogin">
                  <a data-lng="en" [routerLink]="['/register']"> SignUp </a>
                </li>
                <li *ngIf="islogin" (click)="Logout()">
                  <a data-lng="es"> Logout </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul *ngIf="userobj === null" class="header-dropdown">
            <!-- <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
            </li>
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>
            </li> -->
            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> My Account
              <ul class="onhover-show-div justify-content-center">
                <li *ngIf="userobj !== null">
                  <img
                    *ngIf="userobj !== null"
                    [routerLink]="['/pages/profile']"
                    src="{{ userobj.image }}"
                    style="
                      border-radius: 25px;
                      margin-left: auto;
                      margin-right: auto;
                    "
                    height="50px"
                    width="50px"
                    alt=""
                  />
                </li>

                <li *ngIf="!islogin">
                  <a data-lng="en" [routerLink]="['/pages/login']"> Login </a>
                </li>
                <li *ngIf="!islogin">
                  <a data-lng="en" [routerLink]="['/register']"> SignUp </a>
                </li>
                <li *ngIf="islogin" (click)="Logout()">
                  <a data-lng="es"> Logout </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <!-- <app-left-menu></app-left-menu> -->
            <div class="brand-logo">
              <a routerLink="/home/fashion" id="headerlogo">
                <h3 style="font-weight: 800; color: black">DadyStores</h3>
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
