import { Component, OnInit, Input } from "@angular/core";
import { NewProductSlider } from "../../../data/slider";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { ApicallService } from "src/app/services/apicall.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-product-box-vertical-slider",
  templateUrl: "./product-box-vertical-slider.component.html",
  styleUrls: ["./product-box-vertical-slider.component.scss"],
})
export class ProductBoxVerticalSliderComponent implements OnInit {
  @Input() title: string = "New Product"; // Default
  @Input() type: string = "fashion"; // Default Fashion

  public products: any = [];

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(
    public productService: ApicallService,
    private route: Router,
    private toast: ToastService,
    private location: Location,
    private cur: ActivatedRoute
  ) {
    this.productService
      .getproducts()
      .subscribe(
        (response) =>
          (this.products = response.filter((item) => item.type == this.type))
      );
  }

  state(item: any) {
    const currentRoute = this.cur.snapshot.url[0].path;
    console.log("Current route:", currentRoute);
    if (currentRoute === "variants") {
      // Get the current URL
      const currentUrl = this.route.url;
      // Construct the new state object
      const newState = {
        ...this.route.getCurrentNavigation()?.extras.state,
        data: item,
      };
      // Replace the current state in the URL
      this.location.replaceState(currentUrl, "", newState);
    } else {
      // Navigate to the 'variants' route with the new state
      this.route.navigate(["variants"], { state: { data: item } });
    }
  }

  ngOnInit(): void {}
}
