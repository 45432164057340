import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { AuthService } from "src/app/services/auth.service";
@Component({
  selector: "app-product-box-one",
  templateUrl: "./product-box-one.component.html",
  styleUrls: ["./product-box-one.component.scss"],
})
export class ProductBoxOneComponent implements OnInit {
  @Input() product: any;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() thumbnail: boolean = false; // Default False
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc: string;
  selectedCurrency: any;
dollar=280;
  constructor(
    private productService: ProductService,
    private route: Router,
public auth:AuthService,
    private location: Location,
    private cur: ActivatedRoute
  ) {
    this.auth.getSelectedCurrency().subscribe(currency => {
      this.selectedCurrency = currency;
      console.log('selected',this.selectedCurrency)
    });
  }

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => {
        this.loader = false;
      }, 2000); // Skeleton Loader
    }
    this.ImageSrc = this.product.images[0].src;
  }
  roundAmount(num:any): number {
    return num.toFixed(2);
  }
  state(item: any) {
    const currentRoute = this.cur.snapshot.url[0].path;
    console.log("Current route:", currentRoute);
    if (currentRoute === "variants") {
      // Get the current URL
      const currentUrl = this.route.url;
      // Construct the new state object
      const newState = {
        ...this.route.getCurrentNavigation()?.extras.state,
        data: item,
      };
      // Replace the current state in the URL
      this.location.replaceState(currentUrl, "", newState);
    } else {
      // Navigate to the 'variants' route with the new state
      this.route.navigate(["variants"], { state: { data: item } });
    }
  }
  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color);
      }
    }
    return uniqColor;
  }

  // ImageSrc: string;
 

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        });
      }
    });
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
}
