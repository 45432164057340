import { Component, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { map, delay, withLatestFrom } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Platform } from "@ionic/angular";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private platform: Platform,
    private loader: LoadingBarService,
    translate: TranslateService,
    private router: Router
  ) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang("en");
      translate.addLangs(["en", "fr"]);
    }
    this.initializeBackButtonCustomHandler();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      // Call the backButtonHandler method
      this.backButtonHandler();
    });
  }
  initializeBackButtonCustomHandler() {
    document.addEventListener(
      "backbutton",
      () => {
        // If the current route is not the root route, navigate to the root
        if (this.router.url !== "/home/fashion") {
          this.router.navigate(["/home/fashion"]);
        } else {
          // If the current route is the root route, exit the app
          navigator["app"].exitApp();
        }
      },
      false
    );
  }
  backButtonHandler() {
    this.platform.backButton.subscribeWithPriority(10, () => {
      if (this.router.url !== "/home/fashion") {
        // Navigate to the home route or desired route
        this.router.navigate(["/home/fashion"]);
      } else {
        // If on home page, handle the back button functionality, like exiting the app
        navigator["app"].exitApp();
      }
    });
  }
}
