import { Component, OnInit, Input, HostListener } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { check, set } from "src/app/localStorage/LocalStorage";
import { ApicallService } from "src/app/services/apicall.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-header-one",
  templateUrl: "./header-one.component.html",
  styleUrls: ["./header-one.component.scss"],
})
export class HeaderOneComponent implements OnInit {
  @Input() class: string;
  @Input() themeLogo: string = "assets/images/icon/logo.png"; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  public user: any;
  islogin:any
  public userobj: any = {};
  public stick: boolean = false;
  previousUrl: string = "";
  constructor(private auth: AuthService, public router: Router,public apicall:ApicallService) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
        this.GetUserData();
  }

  ngOnInit(): void {

  }
  Logout() {
    set('islogin',false );
    this.auth.logout();

  }
  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }
  val: any;
  goBack() {
    this.router.navigateByUrl(this.previousUrl);
  }
  
  public async GetUserData() {
    this.user = await check("user");
    const userData = JSON.parse(this.user);
    this.islogin = await check('islogin');
    console.log(userData,"da")
    if(userData !== null){
      this.apicall.GetuserProfileData(userData.id).subscribe((profile) => {
        console.log(profile);
        this.userobj = profile[0];
        console.log('adf',profile[0]);
      });
    }else {
      this.userobj = null;
    }
    
  }
}
