import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ProductDetailsMainSlider,
  ProductDetailsThumbSlider,
} from "../../../../shared/data/slider";
import { ProductService } from "../../../../shared/services/product.service";
import { SizeModalComponent } from "../../../../shared/components/modal/size-modal/size-modal.component";
import { ToastService } from "src/app/services/toast.service";
import { NavigationEnd} from "@angular/router";
import { filter } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
@Component({
  selector: "app-product-left-sidebar",
  templateUrl: "./product-left-sidebar.component.html",
  styleUrls: ["./product-left-sidebar.component.scss"],
})
export class ProductLeftSidebarComponent implements OnInit {
  public product: any = {};
  public counter: number = 1;
  public activeSlide: any = 0;
  public selectedSize: any;
  public mobileSidebar: boolean = false;
  public active = 1;

  @ViewChild("sizeChart") SizeChart: SizeModalComponent;

  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;
  interval: any;
  previousUrl: string = '';
  color: any;
  discount: any;
  selectedCurrency: any;
  dollar=280;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    public productService: ProductService,
    public auth:AuthService
  ) {
    this.product = history.state.data;
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.previousUrl = event.url;
    });
    this.auth.getSelectedCurrency().subscribe(currency => {
      this.selectedCurrency = currency;
      console.log('selected',this.selectedCurrency)
    });
  }

  ngOnInit(): void {
    this.product = history.state.data;
    console.log("dddd", this.product);
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
  ngAfterViewInit(): void {
    // this.interval = setInterval(async () => {
    //   this.product = history.state.data;
    // }, 2000);
  }
  roundAmount(num:any): number {
    return num.toFixed(2);
  }
  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        this.color = variants[0].color;
        uniqColor.push(variants[i].color);
      }
    }

    return uniqColor;
  }
  Gonext(item:any){
    this.router.navigate(["/home/storedetail"], { state: { data: item } }); 
  }
  selectColor(item: any, color: any) {
    this.color = color;
    console.log("color", this.color);
  }

  // Get Product Size
  Size(variants) {
    // console.log(variants, "v");
    const uniqSize = [];
    for (let i = 0; i < variants.length; i++) {
      if (uniqSize.indexOf(variants[i].size[i]) === -1 && variants[i].size[i]) {
        uniqSize.push(variants[i].size[i]);
        // console.log(uniqSize, "Test");
      }
    }

    return uniqSize;
  }
  goBack() {
    this.router.navigateByUrl(this.previousUrl);
  }
  selectSize(size: any) {
    this.selectedSize = size;
    console.log("data", this.selectedSize);
  }

  // Increament
  increment() {
    this.counter++;
  }

  // Decrement
  decrement() {
    if (this.counter > 1) this.counter--;
  }

  // Add to cart
  async addToCart(product: any) {
    if (this.selectedSize) {
      product.quantity = this.counter || 1;
      product.size = this.selectedSize;
      product.color = this.color;
      console.log(product, "any");
      const status = await this.productService.addToCart(product);
      if (status) this.router.navigate(["/shop/cart"]);
    } else {
      this.toast.ErrorToast("Select Size", "");
    }
  }

  // Buy Now
  async buyNow(product: any) {
    if (this.selectedSize) {
      product.quantity = this.counter || 1;
      product.size = this.selectedSize;
      product.color = this.color;
      product.discount=this.discount;
      const status = await this.productService.addToCart(product);
      if (status) this.router.navigate(["/shop/checkout"]);
    } else {
      this.toast.ErrorToast("Select Size", "");
    }
  }

  // Add to Wishlist
  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  // Toggle Mobile Sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }
}
